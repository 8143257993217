.logo {
  width: fit-content;
  height: 60px;
  width: 163px;
}

.root {
  padding: 15px 25px 18px;
}

.root .leftCircle {
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(30%);
  z-index: -1;
}

.root a:hover {
  text-decoration: none;
}
.root :global(.navbar) {
  padding: 0;
}
.root :global(.navbar-toggler) {
  border: none;
  padding: 0 0 0 24px;
}
.root :global(.navbar-toggler-icon) {
  background-image: url("./images/ico-close.svg");
  width: 18px;
  height: 18px;
}
.root :global(.collapsed .navbar-toggler-icon) {
  background-image: url("./images/ico-menu.svg");
}
.root :global(.navbar-collapse) {
  justify-content: flex-end;
  transition: var(--animation);
}

.root :global(.dropdown-item:active) {
  background-color: var(--divider-color);
}

/* First logical level of navigation */
.root :global(.navbar-1lvl-item) {
  color: var(--primary-color-font);
  font-size: 16px;
  font-weight: var(--font-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 26px;
  text-decoration: none;
}
.root :global(.navbar-1lvl-link) {
  width: 100%;
}

.dropdownLinkWrapper {
  position: relative;
  cursor: pointer;
}

.dropdownLinkWrapper:hover {
  color: var(--secondary-color);
}

.root :global(.dropdown-toggle) .dropdownLinkWrapper {
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.root :global(.dropdown-toggle) {
  position: relative;
}
.root :global(.nav-link) {
  padding: 0;
  white-space: pre-wrap;
}

.root :global(.dropdown-toggle::after) {
  display: none !important;
}

.root :global(.dropdown-toggle::after),
.root .dropdownToggleAffter::after {
  content: "";
  display: block;
  border: solid var(--primary-color);
  border-width: 0 3px 3px 0;
  display: inline-block;
  transition: var(--animation);
  position: absolute;
  right: 5px;
  top: 0;
}
.root .dropdownToggleAffter {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 5px;
  top: 0;
}

.root .dropdownToggleAffter::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
  border-width: 0 2px 2px 0;
  top: 15px;
}

.root :global(.show > .dropdown-toggle::after) {
  border-color: var(--primary-color);
  transform: rotate(-135deg);
  right: 2px;
}

/* Second logical level of navigation */
.root :global(.navbar-2lvl-menu .show.dropdown-menu),
.root :global(.navbar-3lvl-menu .show.dropdown-menu) {
  border: 0;
  background-color: var(--background-light-grey);
}
.root :global(.navbar-2lvl-menu .dropdown),
.root :global(.navbar-3lvl-menu .dropdown) {
  border: 0;
}
.root :global(.navbar-2lvl-item) {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
  color: var(--primary-color-font);
  font-weight: var(--font-medium);
  box-shadow: none;
}
.root :global(.navbar-2lvl-item.dropdown-toggle) {
  position: relative;
  background-color: transparent;
  box-shadow: none;
  width: 100%;
  margin: 0;
}
.root :global(.navbar-2lvl-item.dropdown-toggle:after),
.root :global(.navbar-2lvl-item) .dropdownToggleAffter {
  border-color: var(--primary-color-font);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg) translateY(-50%);
  position: absolute;
  right: 30px;
  top: 43%;
  color: var(--primary-color-font);
}

.root :global(.navbar-2lvl-menu .show > .dropdown-toggle:after),
.root :global(.navbar-3lvl-menu .show > .dropdown-toggle:after) {
  border-color: var(--primary-color);
  transform: rotate(225deg);
}
.root :global(.navbar-2lvl-menu .dropdown-toggle:active),
.root :global(.navbar-3lvl-menu .dropdown-toggle:active) {
  background-color: var(--divider-color);
  border-color: transparent;
  box-shadow: none;
}
.root :global(.navbar-2lvl-menu button.dropdown-toggle:focus),
.root :global(.navbar-3lvl-menu button.dropdown-toggle:focus) {
  box-shadow: none;
}
.root :global(.navbar-2lvl-menu .dropdown-toggle > a),
.root :global(.navbar-3lvl-menu .dropdown-toggle > a) {
  color: var(--primary-color-font);
  text-decoration: none;
  box-shadow: none;
}
.root :global(.navbar-2lvl-menu .dropdown-toggle[aria-expanded="true"] > a),
.root :global(.navbar-3lvl-menu .dropdown-toggle[aria-expanded="true"] > a) {
  color: var(--primary-color);
  box-shadow: none;
}

/*  Third logical level of navigation */
.root :global(.navbar-3lvl-menu .show.dropdown-menu) {
  border-bottom: none;
}
.root :global(.dropdown-item.navbar-3lvl-item) {
  font-size: 16px;
  font-weight: var(--font-regular);
  padding: 10px 24px;
}
.root :global(.dropdown-item.navbar-3lvl-item:active) {
  text-decoration: none;
  color: var(--primary-color);
  background-color: transparent;
}
.submenuHeader {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: var(--font-medium);
  color: #999999;
  line-height: 1.6;
  padding: 7px 24px 7px 24px;
  display: none;
}
.buttonWrapper {
  width: 108px;
  height: 46px;
  margin-left: 15px;
}

.buttonWrapper > a > button {
  width: 108px;
  height: 46px;
  border-radius: 10px;
  padding: 12px 0px;
}

.buttonWrapper > a > button > div {
  font-size: 12px;
}

.buttonWrapper {
  display: flex;
  column-gap: 20px;
}

.mobileHidden,
.buttonWrapper.hiddenSmallDesktop,
.root .mobileSubNavInfoWrapper,
.mobileLanguageSwitchWrapper {
  display: none;
}

@media (min-width: 992px) {
  .root {
    padding: 5px 0;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.06);
  }
  .root :global(.navbar-nav) {
    border-top: none;
    align-items: center;
    column-gap: 25px;
  }
  .root :global(.nav-item) {
    position: relative;
  }
  .root .dropdownToggleAffter {
    display: none;
  }
  .root :global(.dropdown-toggle::after) {
    display: block !important;
  }
  .root :global(.navbar-2lvl-menu .show.dropdown-menu),
  .root :global(.navbar-3lvl-menu .show.dropdown-menu) {
    padding: 10px 0;
  }
  .root :global(.navbar-2lvl-menu .show.dropdown-menu),
  .root :global(.show.dropdown-menu) {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.08);
    background-color: white;
    border: none;
    transition: margin-top 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    margin: 0px;
    width: min(300px, 500px);
    top: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .root :global(.navbar-3lvl-menu .show.dropdown-menu) {
    top: 0;
    padding: 0;
  }

  .logo {
    padding-left: 0;
  }
  .root :global(.navbar-1lvl-item a) {
    color: var(--primary-color-font);
    font-weight: var(--font-medium);
    width: fit-content;
    font-size: 14px;
    padding: 0;
    line-height: 1.5;
  }
  .root :global(.navbar-1lvl-item) {
    border-bottom: none;
    font-size: 12px;
    line-height: 1.5;
    font-weight: var(--font-medium);
    width: fit-content;
    cursor: pointer;
    padding: 0;
  }
  .root :global(.navbar-3lvl-menu .navbar-1lvl-item) {
    padding: 0;
    margin: 0;
    line-height: 22px;
  }
  .root :global(.navbar-expand-lg .navbar-nav .nav-link) {
    padding-right: 0;
    padding-left: 0;
  }
  .root :global(.navbar-2lvl-menu:hover .navbar-1lvl-item),
  .root :global(.navbar-2lvl-menu:hover .navbar-1lvl-item > a),
  .root :global(.navbar-3lvl-menu:hover .navbar-1lvl-item),
  .root :global(.navbar-3lvl-menu:hover .navbar-1lvl-item > a),
  .root :global(.navbar-1lvl-item:hover) {
    color: var(--secondary-color);
    transition: color 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  .root :global(.dropdown-toggle) .dropdownLinkWrapper {
    padding-right: 20px;
  }
  .root :global(.navbar-2lvl-menu .dropdown-toggle.nav-link:after),
  .root :global(.navbar-3lvl-menu .dropdown-toggle.nav-link:after) {
    border: solid var(--primary-color);
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2.5px;
    transition: border-color 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    top: 50%;
    transform: rotate(45deg) translate(-50%, -50%);
  }
  .root :global(.navbar-3lvl-menu) {
    padding-right: 20px;
  }
  .root :global(.navbar-3lvl-menu .dropdown-toggle.nav-link:after) {
    transform: rotate(317deg) translateY(-2px);
  }
  .root :global(.navbar-2lvl-menu:hover .dropdown-toggle.nav-link:after) {
    border: solid var(--secondary-color);
    border-width: 0 2px 2px 0;
  }
  .root :global(.dropdown > .navbar-2lvl-item:after) {
    color: var(--primary-color);
    content: ">";
    right: 10px;
    position: absolute;
    transform: none;
    top: 2px;
    border: none;
  }
  .root :global(.dropdown-menu.show .navbar-2lvl-item:after) {
    transform: none;
    right: 10px;
  }
  .root :global(.navbar-3lvl-item),
  .root :global(.navbar-2lvl-menu .dropdown-toggle > a),
  .root :global(.navbar-2lvl-item) {
    color: var(--primary-color);
    line-height: 1.5;
  }
  .root :global(.navbar-3lvl-item),
  .root :global(.dropdown-item.navbar-3lvl-item),
  .root :global(.navbar-2lvl-item) {
    padding: 7px 24px;
    transition: var(--animation);
    cursor: pointer;
    font-size: 18px;
    font-weight: var(--font-medium);
  }
  .root :global(.navbar-2lvl-menu .navbar-2lvl-item[aria-expanded="true"]),
  .root :global(.navbar-3lvl-item:hover),
  .root :global(.navbar-2lvl-item:hover) {
    background-color: var(--primary-color);
    color: white;
  }
  .root :global(.navbar-2lvl-menu .dropdown-item[aria-expanded="true"]::after),
  .root :global(.navbar-2lvl-menu .dropdown-item[aria-expanded="true"] > a) {
    color: white;
  }

  .root :global(.navbar-3lvl-menu .dropdown-menu) {
    margin: 0px;
    top: 0;
    left: 100%;
    width: 250px;
  }

  .root
    :global(.dropdown-menu[aria-labelledby="collasible-nav-dropdown-Oferta"]) {
    left: -80px;
  }
  .submenuHeader {
    display: block;
  }
  .menuVerticalDivider {
    background-color: var(--divider-color);
    height: auto;
    margin: 15px 10px;
    width: 1px;
  }
  .searchWrapper {
    margin-left: 25px;
    margin-right: 5px;
    padding: 20px 0;
  }

  .root :global(.navbar-1lvl-item) {
    white-space: break-spaces;
  }

  .buttonWrapper.mobileHidden {
    width: auto;
    height: auto;
    display: flex;
    column-gap: 20px;
    margin: 0 20px;
  }

  @media (min-width: 768px) {
    .desktopHidden {
      display: none;
    }
    .mobileHidden {
      display: block;
    }
    .hiddenSmallDesktop {
      display: none;
    }
    .root :global(.navbar) {
      padding-top: 10px;
    }
  }

  @media (min-width: 1200px) {
    .hiddenSmallDesktop {
      display: block;
    }

    .root :global(.navbar) > :global(.container) {
      min-width: var(--nav-width);
    }
  }
}

@media (min-width: 992px) {
  .root :global(.dropdown-menu) .dropdownLinkWrapper {
    color: var(--primary-color-font);
    padding: 10px;
    font-size: 14px;
    transition: var(--animation);
    padding: 10px 20px;
  }

  .root :global(.dropdown-menu) .dropdownLinkWrapper:hover {
    color: var(--secondary-color);
  }

  .desktopHidden {
    display: none;
  }
}

@media (max-width: 992px) {
  .dropdownLinkWrapper,
  .root :global(.navbar-1lvl-link) {
    padding: 10px 0;
  }
  .root :global(.navbar-collapse) {
    padding-top: 30px;
  }

  .buttonWrapper {
    margin-left: 0;
    margin-top: 10px;
  }

  .root .leftCircle {
    display: none;
  }

  .root :global(.navbar-2lvl-menu .show > .dropdown-toggle:after),
  .root :global(.navbar-2lvl-menu .show) > .dropdownToggleAffter {
    right: 2px;
  }

  .root :global(.navbar-3lvl-menu .navbar-1lvl-item) {
    font-weight: 300;
  }

  .root :global(.navbar-collapse.show) {
    position: relative;
  }

  .root :global(.navbar-nav > .navbar-2lvl-menu),
  .root :global(.navbar-3lvl-menu) {
    position: unset;
  }

  .root :global(.navbar-2lvl-menu .show.dropdown-menu) {
    position: absolute;
    background-color: #fff;
    width: 100%;
    min-height: 100%;
    top: 0;
    padding-top: 35px;
  }

  .root .mobileSubNavInfoWrapper {
    display: block;
    border-bottom: 1px solid #dfe6ef;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }

  .root .backButton {
    display: flex;
    align-items: center;
    column-gap: 5px;
    color: #a5aab4;
    background-color: transparent;
    border: none;
    padding: 0;
    margin-bottom: 15px;
  }

  .root .backButton .backButtonArrow {
    width: 6px;
    height: 6px;
    border: solid #a5aab4;
    border-width: 0 1.5px 1.5px 0;
    display: inline-block;
    transform: rotate(135deg);
  }

  .root .parentName {
    color: #a5aab4;
    margin-bottom: 0;
  }

  .root .dropdownLinkWrapper {
    font-weight: 600;
  }

  .desktopLanguageSwitchWrapper > div {
    display: none;
  }

  .mobileLanguageSwitchWrapper {
    display: flex;
    align-items: center;
    padding: 30px 0;
  }

  .mobileLanguageSwitchWrapper .mobileLanguageLabel {
    font-weight: 600;
    margin-bottom: 0;
    margin-right: 5px;
  }
}

@media (max-width: 991px) {
  .root :global(.navbar-collapse) {
    order: 2;
  }
  .root :global(.navbar-toggler) {
    order: 1;
  }
  .desktopLanguageSwitchWrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    column-gap: 10px;
  }

  .root :global(#language-switch-dropdown.dropdown-toggle::after),
  .root
    :global(#language-switch-dropdown.dropdown-toggle)
    .dropdownToggleAffter {
    display: none;
  }

  .root :global(.dropdown-menu.show) {
    left: auto;
    right: 0;
  }

  .root :global(.navbar-2lvl-item) {
    font-size: 15px;
    text-align: left;
    background-color: #fff;
    width: max-content;
    padding: 8px;
  }
}
